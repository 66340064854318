











































































































































































































































































































































































































































































































































































































































































// import axios from 'axios';
import { WorkingPeriodResource } from '@/classes/WorkingPeriod.resource';
import { TaskResource } from '@/classes/Task.resource';
import { MeetingParticipant } from '@/classes/MeetingParticipant.resource';
import { TaskAssignment } from '@/classes/TaskAssignments.resource';
import { SelectItemOption } from '@/classes/base/elements';
import { MeetingStore } from '@/store';
import { createDocx, createLogObject, getAssignmentOptions, getPercentage, calculateSwitchValues } from '../helper/index';
import moment from 'moment';
import { Vue, Component } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';

@Component({
	components: {
		BaseDialog: () => import('../components/base/BaseDialog.vue'),
		BaseButton: () => import('../components/base/BaseButton.vue'),
		BaseSelect: () => import('../components/base/BaseSelect.vue'),
		DotListItem: () => import('../components/base/DotListItem.vue'),
		ProgressCircular: () => import('../components/base/ProgressCircular.vue'),
		ResponsibleSelect: () => import('../components/ResponsibleSelect.vue'),
		AgendaPointForm: () => import('../components/AgendaPointForm.vue'),
		VueEditor
	},
	beforeRouteLeave(to, from, next) {
		const self = this as any;
		self.exitUrl = to.path;

		if (self.workingPeriod.status === 'running') {
			if (self.exitConfirmed) {
				next();
			} else {
				self.exitDialog = true;
			}
		} else {
			next();
		}
	}
})
export default class MeetingView extends Vue {
	// Meeting
	public workingPeriod: WorkingPeriodResource = null as unknown as WorkingPeriodResource;
	confirmJumpForward = false;
	now: Date = new Date();
	public startMeetFlag = false;
	public finishMeetFlag = false;
	public agendaListHovering = false;
	public meetingStartedAt = 0;
	customToolbar = [
		['bold', 'italic', 'underline'],
		[{ list: 'ordered' }, { list: 'bullet' }]
	];

	// Tasks
	public activeTaskKey = 0;
	public editedTaskId = 0;
	public addToSelectedTask = false;
	public confirmTaskDelete = false;
	public taskToDelete = null as unknown as TaskResource;

	// Assignment
	public nextAssignmentId = 0;
	public editedAssignmentId = 0;
	public assignmentType = ''; //task or note
	public assignmentContent = '';
	public assignmentvHtmlContent = '';
	public assignmentTaskKeyToDelete = 0;
	//endResult = [];
	public assignmentDeadline = '';
	public responsibleForAssignment: MeetingParticipant[] = [];
	public assignmentEditMode = false;
	public assignmentToDelete = null as unknown as TaskAssignment;
	public confirmAssignmentDelete = false;

	//Others
	public pauseScreen = false;
	public bufferHit = false;
	//public overtimeTaskCounter = 0;
	public isHighlighted = false;
	displayProgressToolTip = false;
	hoveredProgressToolTipId = 0;

	public exitDialog = false;
	public exitConfirmed = false;
	public exitUrl = '';
	public pausedDialog = false;

	//Participant
	public nextParticipantId = 0;
	public displayResponsibleForAssignmentList = false;
	public extraParticipantDialog = false;
	public newParticipantName = '';
	public personTasks: any[] = [];
	public showExtraAgendaPointForm = false;

	async mounted(): Promise<void> {
		const id = this.$route.params.meetingId;

		if (MeetingStore.workingPeriods.length > 0) {
			const target = MeetingStore.workingPeriods.find((meet) => Number(meet.id) === Number(id));
			if (target) {
				this.workingPeriod = target;
			}
		}
		if (this.workingPeriod) {
			this.nextParticipantId = this.workingPeriod.participants.length; // next from active
			this.nextAssignmentId = this.numberOfAllAssignments;
			this.editedTaskId = this.workingPeriod.tasks[this.workingPeriod.activeTask].id;
			this.activeTaskKey = this.workingPeriod.activeTask;

			if (this.workingPeriod.status === 'paused') {
				this.pausedDialog = true;
			}
			if (this.workingPeriod.secondsCounted > 0) {
				this.startMeetFlag = true;
			}
		}
		window.addEventListener('beforeunload', () => {
			this.closePage();
		});
		this.getPersonTasks();
	}

	traverse(node) {
		const result = [];
		node.childNodes.forEach((child) => {
			if (child.nodeType === Node.ELEMENT_NODE) {
				const obj = {
					tag: child.tagName.toLowerCase()
				};

				if (child.tagName === 'UL' || child.tagName === 'OL') {
					obj.children = this.traverse(child);
				} else if (child.tagName === 'LI') {
					obj.content = this.traverse(child);
				} else {
					obj.content = child.textContent.trim();
					if (child.style.cssText) {
						obj.style = child.style.cssText;
					}
					if (child.classList.length > 0) {
						obj.class = child.classList.value;
					}
					if (child.childNodes.length > 0) {
						obj.content = this.traverse(child);
					}
				}
				result.push(obj);
			} else if (child.nodeType === Node.TEXT_NODE) {
				if (child.textContent.trim() !== '') {
					result.push({ content: child.textContent.trim() });
				}
			}
		});
		return result;
	}

	parseHTMLContent(content) {
		const parser = new DOMParser();
		const parsedContent = parser.parseFromString(content, 'text/html');
		let endResult = {};

		endResult = this.traverse(parsedContent.body);
		return endResult;
	}

	async beforeUnmount(): Promise<void> {
		window.removeEventListener('beforeunload', () => {
			this.closePage();
		});
	}

	// Meeting
	async toggleProgressToolTip(taskId: number): Promise<void> {
		if (!this.displayProgressToolTip) {
			this.displayProgressToolTip = true;
			this.hoveredProgressToolTipId = taskId;
		} else if (this.displayProgressToolTip && this.hoveredProgressToolTipId === taskId) {
			this.displayProgressToolTip = false;
		} else if (this.displayProgressToolTip && this.hoveredProgressToolTipId !== taskId) {
			this.hoveredProgressToolTipId = taskId;
		}
	}

	async updateStore(): Promise<void> {
		MeetingStore.updateStore();
	}

	async closePage(): Promise<void> {
		if (this.workingPeriod.isRunning) {
			clearInterval(this.workingPeriod.interval);
			this.workingPeriod.interval = null;
			this.workingPeriod.status = 'paused';
			this.workingPeriod.isRunning = false;
			MeetingStore.updateStore();
		}
	}

	async leaveMeeting(): Promise<void> {
		this.exitConfirmed = true;
		clearInterval(this.workingPeriod.interval);
		this.workingPeriod.interval = null;
		this.workingPeriod.status = 'paused';
		this.workingPeriod.isRunning = false;
		MeetingStore.updateStore();
		this.$router.push(this.exitUrl);
	}

	async restartMeeting(): Promise<void> {
		this.workingPeriod.secondsCounted = 0;
		this.workingPeriod.secondsLeft = this.workingPeriod.secondsNeeded;
		this.workingPeriod.tasks.forEach((task) => {
			task.secondsCounted = 0;
			task.overtimeSecondsCounted = 0;
			task.secondsNeeded = task.startSecondsNeeded;
			task.secondsLeft = task.startSecondsNeeded;
			task.isRunning = false;
		});
		this.workingPeriod.activeTask = 0;
		//this.workingPeriod.percentage = this.workingPeriod.initialPercentage;
		this.workingPeriod.breakpoints = calculateSwitchValues(this.workingPeriod.tasks);
		this.workingPeriod.percentage = getPercentage(this.workingPeriod.tasks);
		this.startWorkingPeriod();
	}

	async openTooltip(taskId: number): Promise<void> {
		setTimeout(() => {
			const tooltip = document.getElementById('progress-tooltip');
			if (tooltip) {
				tooltip.focus();
			}
		}, 1);
		this.hoveredProgressToolTipId = taskId;
		this.displayProgressToolTip = true;
	}

	async closeTooltip(): Promise<void> {
		this.displayProgressToolTip = false;
	}

	async checkJumpModalWarning(): Promise<void> {
		if (this.workingPeriod.tasks[this.workingPeriod.activeTask].assignments.length > 0) {
			this.createAssignment();
			this.jumpForward();
		} else {
			this.confirmJumpForward = true;
		}
	}

	async checkFinishModalWarning(): Promise<void> {
		if (this.workingPeriod.activeTask === this.workingPeriod.tasks.length - 1 && this.workingPeriod.tasks[this.workingPeriod.activeTask].assignments.length === 0) {
			this.finishMeetFlag = true;
			this.confirmJumpForward = true;
		} else {
			this.createAssignment();
			this.finishWorkingPeriod();
		}
	}

	updateDateTime(): void {
		this.now = moment().toDate();
	}

	async updateFinishedMeet(): Promise<void> {
		if (this.workingPeriod.status === 'finished') {
			MeetingStore.updateWorkingPeriod(this.workingPeriod);
		}
	}

	async pauseWorkingPeriod(): Promise<void> {
		if (this.workingPeriod.isRunning == true) {
			this.workingPeriod.isRunning = false;
			clearInterval(this.workingPeriod.interval);
			this.workingPeriod.interval = null;
			this.pauseScreen = true;
		}
		if (this.workingPeriod.isRunning == false) {
			if (!this.workingPeriod.interval) {
				this.workingPeriod.interval = setInterval(() => {
					//Pause timer
					if (this.workingPeriod.pause.secondsLeft > 0) {
						this.updatePauseSecondsLeft();
						this.updatePauseSecondsCounted();
					} else {
						clearInterval(this.workingPeriod.interval);
						this.workingPeriod.interval = null;
						this.pauseScreen = false;
						this.startWorkingPeriod();
					}
					this.updateDateTime();
				}, 1000);
			}
		}
	}

	async overtimeWorkingPeriod(): Promise<void> {
		if (this.workingPeriod.isRunning == true) {
			clearInterval(this.workingPeriod.interval);
			this.workingPeriod.interval = null;

			if (!this.workingPeriod.interval) {
				this.workingPeriod.interval = setInterval(() => {
					if (this.workingPeriod.status === 'overtime') {
						this.updateOvertimeSecondsCounted();
					}
					this.updateDateTime();
				}, 1000);
			}
		}
	}

	async finishWorkingPeriod(): Promise<void> {
		if (this.workingPeriod.isRunning == true) {
			this.workingPeriod.isRunning = false;
			clearInterval(this.workingPeriod.interval);
			this.workingPeriod.interval = null;
			this.workingPeriod.tasks[this.workingPeriod.activeTask].isRunning = false;
			this.workingPeriod.tasks[this.workingPeriod.activeTask].status = 'finished';
			this.workingPeriod.status = 'finished';
			await this.updateFinishedMeet();
			MeetingStore.updateStore();
			let gapFromPlannedStart = 0;
			if (this.startMeetFlag) {
				const dateString = this.workingPeriod.meetDay;
				const timeString = this.workingPeriod.meetTime;
				const date = new Date(dateString);
				const [hours, minutes] = timeString.split(':');
				date.setHours(Number(hours));
				date.setMinutes(Number(minutes));
				const timestamp = date.getTime();
				gapFromPlannedStart = ((timestamp - this.meetingStartedAt) / 1000) * -1;
			}

			createLogObject(this.workingPeriod, 'Finish', undefined, undefined, gapFromPlannedStart);

			/*await axios
				.post(MeetingStore.apiUri, meetingLog)
				.then(function (response) {
					console.log(response);
				})
				.catch(function (error) {
					console.log(error);
				});
				*/
		}
	}

	async startWorkingPeriod(): Promise<void> {
		if (this.workingPeriod.isRunning == false) {
			clearInterval(this.workingPeriod.interval);
			this.workingPeriod.interval = null;
			this.workingPeriod.isRunning = true;
			this.workingPeriod.status = 'running';
			this.workingPeriod.start;
			this.meetingStartedAt = Date.now();
			this.startMeetFlag = true;
			if (!this.workingPeriod.interval) {
				this.workingPeriod.interval = setInterval(() => {
					//workingPeriod is running
					if (this.workingPeriod.secondsLeft >= 0) {
						this.updateSecondsLeft();
						this.updateSecondsCounted();
						//Update time on active task
						if (this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft > 0) {
							this.workingPeriod.tasks[this.workingPeriod.activeTask].isRunning = true;
							this.workingPeriod.tasks[this.workingPeriod.activeTask].status = 'running';
							this.updateTaskSecondsLeft(this.workingPeriod.tasks[this.workingPeriod.activeTask]);
							this.updateTaskSecondsCounted(this.workingPeriod.tasks[this.workingPeriod.activeTask]);
						}
						//When task time is over and task is not last (can't substractPercentually without future task) continue to update active tasks time and substractPercentually() from future tasks.
						else if (this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft <= 0 && this.workingPeriod.activeTask < this.workingPeriod.tasks.length) {
							this.updateTaskSecondsLeft(this.workingPeriod.tasks[this.workingPeriod.activeTask]);
							this.updateTaskSecondsCounted(this.workingPeriod.tasks[this.workingPeriod.activeTask]);
							this.updateTaskSecondsNeeded(this.workingPeriod.tasks[this.workingPeriod.activeTask]);
							this.updateTaskOvertimeSecondsCounted(this.workingPeriod.tasks[this.workingPeriod.activeTask]);
							if (this.tasksWithSecondsLeft.length > 0) {
								//this.substractFromOne(this.tasksWithSecondsLeft[this.overtimeTaskCounter]);
								this.substractPercentually();
							}
						}
					}
					if (this.workingPeriod.secondsLeft < 0 && this.workingPeriod.buffer.secondsLeft > 0) {
						this.bufferHit = true;
						this.updateBufferSecondsLeft();
						this.updateBufferSecondsCounted();
						this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsCounted = this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsNeeded;
						this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft = 0;
					}
					//Overtime
					if (this.workingPeriod.buffer.secondsLeft <= 0 && this.workingPeriod.secondsLeft <= 0) {
						this.workingPeriod.status = 'overtime';
						this.updateOvertimeSecondsCounted();
					}
					this.updateDateTime();
				}, 1000);
			}
		}
	}

	async jumpForward(): Promise<void> {
		//if not last task
		if (this.workingPeriod.activeTask != this.workingPeriod.tasks.length - 1) {
			//if no task overtime
			if (this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft > 0) {
				// if not last , length 3 [0,1,2]
				this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsNeeded = this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsCounted; //secondsNeeded to Counted
				let newDivisor = 0;
				for (let i = this.workingPeriod.activeTask + 1; i < this.workingPeriod.tasks.length; ) {
					// foreach after active task
					//getting percentage
					newDivisor = newDivisor + this.workingPeriod.percentage[i];
					i++;
				}
				for (let i = this.workingPeriod.activeTask + 1; i < this.workingPeriod.tasks.length; ) {
					//foreach element after active task get new seconds Needed + left
					this.workingPeriod.tasks[i].secondsNeeded = this.workingPeriod.tasks[i].secondsNeeded + (this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft * this.workingPeriod.percentage[i]) / newDivisor; //  + 1
					this.workingPeriod.tasks[i].secondsLeft = this.workingPeriod.tasks[i].secondsLeft + (this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft * this.workingPeriod.percentage[i]) / newDivisor; // + 1
					i++;
				}
				this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft = 0;
				this.workingPeriod.breakpoints = calculateSwitchValues(this.workingPeriod.tasks);
				this.workingPeriod.percentage = getPercentage(this.workingPeriod.tasks);
				this.workingPeriod.timeBeforeJump = this.workingPeriod.secondsCounted;
				this.workingPeriod.tasks[this.workingPeriod.activeTask].isRunning = false;
				this.workingPeriod.tasks[this.workingPeriod.activeTask].status = 'finished';
				this.workingPeriod.activeTask++;
			} else {
				// if overtime
				//finish active task
				this.workingPeriod.tasks[this.workingPeriod.activeTask].isRunning = false;
				this.workingPeriod.tasks[this.workingPeriod.activeTask].status = 'finished';
				this.workingPeriod.activeTask++;

				//start new active task
				this.workingPeriod.tasks[this.workingPeriod.activeTask].isRunning = true;
				this.workingPeriod.tasks[this.workingPeriod.activeTask].status = 'running';
			}
			this.editedTaskId = this.workingPeriod.tasks[this.workingPeriod.activeTask].id;
			this.activeTaskKey = this.workingPeriod.activeTask;
		}
		this.scrollToNext(String(this.workingPeriod.tasks[this.workingPeriod.activeTask].id));
	}

	async scrollToNext(id: string): Promise<void> {
		const list = document.getElementById('agendaList');
		const nextElement = document.getElementById(id);
		if (list && nextElement) {
			const top = nextElement.offsetTop;
			list.scrollTo(0, top - 50);
		}
	}

	async report(workingPeriod: WorkingPeriodResource): Promise<void> {
		createDocx(workingPeriod);
	}

	//Tasks

	addExtraTask(newTaskObject): void {
		console.log(newTaskObject);
		if (newTaskObject.task && newTaskObject.position) {
			//this.workingPeriod.tasks.push(newTask);
			this.workingPeriod.tasks.splice(newTaskObject.position, 0, newTaskObject.task);
			// update time
			this.workingPeriod.duration = this.workingPeriod.duration + newTaskObject.task.duration;
			this.workingPeriod.secondsLeft = this.workingPeriod.secondsLeft + newTaskObject.task.startSecondsNeeded;
			this.workingPeriod.secondsNeeded = this.workingPeriod.secondsNeeded + newTaskObject.task.startSecondsNeeded;
			this.workingPeriod.breakpoints = calculateSwitchValues(this.workingPeriod.tasks);
			this.workingPeriod.percentage = getPercentage(this.workingPeriod.tasks);
			this.showExtraAgendaPointForm = false;
		}
	}

	async deleteTask(taskId: number): Promise<void> {
		let taskKeyToDelete = this.workingPeriod.tasks.findIndex((task) => task.id === taskId);
		if (taskKeyToDelete) {
			this.workingPeriod.secondsNeeded = this.workingPeriod.secondsNeeded - this.workingPeriod.tasks[taskKeyToDelete].secondsNeeded;
			this.workingPeriod.secondsLeft = this.workingPeriod.secondsLeft - this.workingPeriod.tasks[taskKeyToDelete].secondsLeft;
			this.workingPeriod.tasks = this.workingPeriod.tasks.filter((task) => {
				return task.id !== taskId;
			});
			this.workingPeriod.breakpoints = calculateSwitchValues(this.workingPeriod.tasks);
			this.workingPeriod.percentage = getPercentage(this.workingPeriod.tasks);
		}
	}

	async moveTaskBack(task: TaskResource): Promise<void> {
		const index = this.workingPeriod.tasks.indexOf(task, 0);
		if (index >= 1) {
			const prevIndex = index - 1;
			const element = this.workingPeriod.tasks[index];
			this.workingPeriod.tasks.splice(index, 1);
			this.workingPeriod.tasks.splice(prevIndex, 0, element);
		}
	}

	async moveTaskForward(task: TaskResource): Promise<void> {
		const index = this.workingPeriod.tasks.indexOf(task, 0);
		if (index < this.workingPeriod.tasks.length - 1) {
			const nextIndex = index + 1;
			const element = this.workingPeriod.tasks[index];
			this.workingPeriod.tasks.splice(index, 1);
			this.workingPeriod.tasks.splice(nextIndex, 0, element);
		}
	}

	getTaskOptions(task: TaskResource): SelectItemOption[] {
		const options: SelectItemOption[] = [];
		if (this.workingPeriod.status === 'not-started') {
			options.push({
				name: 'add',
				description: 'Add another Task',
				icon: {
					name: 'plus'
				}
			});
			if (this.workingPeriod.tasks.length > 1) {
				options.push({
					name: 'delete',
					description: 'Delete',
					icon: {
						name: 'close'
					}
				});
				if (!this.isFirstTaskWithId(task.id)) {
					options.push({
						name: 'move-up',
						description: 'Move Up',
						icon: {
							name: 'chevron-up-circle-outline'
						}
					});
				}

				if (!this.isLastTaskWithId(task.id)) {
					options.push({
						name: 'move-down',
						description: 'Move Down',
						icon: {
							name: 'chevron-down-circle-outline'
						}
					});
				}
			}
		} else if (this.workingPeriod.status === 'running' || this.workingPeriod.status === 'overtime') {
			if (task.status !== 'finished') {
				options.push({
					name: 'add',
					description: 'Add another Task',
					icon: {
						name: 'plus'
					}
				});
			}
			if (this.workingPeriod.tasks.length > 1) {
				if (task.status === 'not-started') {
					options.push({
						name: 'delete',
						description: 'Delete',
						icon: {
							name: 'close'
						}
					});

					//wp running or overtime, tasks > 1, prev task status == not started

					const index = this.workingPeriod.tasks.findIndex((t) => t.id === task.id);
					const prevTaskIndex = index - 1;
					//const prevTaskIsNotStarted = this.workingPeriod.tasks[prevTaskIndex] && this.workingPeriod.tasks[prevTaskIndex].status === 'not-started';

					if (this.workingPeriod.tasks[prevTaskIndex] && this.workingPeriod.tasks[prevTaskIndex].status === 'not-started') {
						options.push({
							name: 'move-up',
							description: 'Move Up',
							icon: {
								name: 'chevron-up-circle-outline'
							}
						});
					}

					if (!this.isLastTaskWithId(task.id)) {
						options.push({
							name: 'move-down',
							description: 'Move Down',
							icon: {
								name: 'chevron-down-circle-outline'
							}
						});
					}
				}
			}
		}
		/* else if (this.workingPeriod.status === 'finished') {
			options.push({
				name: 'add',
				description: 'Add another Task',
				icon: {
					name: 'plus'
				}
			});
		}*/

		return options;
	}

	isLastTaskWithId(taskId: number): number {
		// Find the index of the object with the given id
		const index = this.workingPeriod.tasks.findIndex((task) => task.id === taskId);

		// Check if the index is the last index in the array
		return index !== -1 && index === this.workingPeriod.tasks.length - 1;
	}

	isFirstTaskWithId(taskId: number): number {
		// Find the index of the object with the given id
		const index = this.workingPeriod.tasks.findIndex((task) => task.id === taskId);

		// Check if the index is the first index in the array
		return index === 0;
	}

	//Timer

	async updateOvertimeSecondsCounted(): Promise<undefined | number> {
		if (this.workingPeriod.status === 'overtime' && this.workingPeriod.isRunning == true) return this.workingPeriod.overtimeSecondsCounted++;
	}

	async updateBufferSecondsLeft(): Promise<undefined | number> {
		if (this.workingPeriod.buffer.secondsLeft > 0) return this.workingPeriod.buffer.secondsLeft--;
	}

	async updateBufferSecondsCounted(): Promise<undefined | number> {
		if (this.workingPeriod.buffer.secondsLeft > 0) return this.workingPeriod.buffer.secondsCounted++;
	}

	async updatePauseSecondsLeft(): Promise<undefined | number> {
		if (this.workingPeriod.pause.secondsLeft > 0 && this.workingPeriod.isRunning == false) return this.workingPeriod.pause.secondsLeft--;
	}

	async updatePauseSecondsCounted(): Promise<undefined | number> {
		if (this.workingPeriod.pause.secondsLeft > 0 && this.workingPeriod.isRunning == false) return this.workingPeriod.pause.secondsCounted++;
	}

	async updateSecondsLeft(): Promise<undefined | number> {
		if (this.workingPeriod.isRunning == true) return this.workingPeriod.secondsLeft--;
	}

	async updateSecondsCounted(): Promise<undefined | number> {
		if (this.workingPeriod.isRunning == true) return this.workingPeriod.secondsCounted++;
	}

	async updateTaskSecondsLeft(task: TaskResource): Promise<number> {
		return task.secondsLeft--;
	}

	async updateTaskSecondsCounted(task: TaskResource): Promise<number> {
		return task.secondsCounted++;
	}

	async updateTaskOvertimeSecondsCounted(task: TaskResource): Promise<number> {
		return task.overtimeSecondsCounted++;
	}

	async updateTaskSecondsNeeded(task: TaskResource): Promise<undefined | number> {
		if (task.secondsCounted >= task.secondsNeeded) return task.secondsNeeded++;
	}

	async substractTaskSecondsNeeded(task: TaskResource): Promise<number> {
		return task.secondsNeeded--;
	}

	async substractTaskSecondsLeft(task: TaskResource): Promise<number> {
		return task.secondsLeft--;
	}

	/* async substractFromOne(task: TaskResource): Promise<void> {
		if (task.secondsNeeded > 0) this.substractTaskSecondsNeeded(task);
		if (task.secondsLeft > 0) this.substractTaskSecondsLeft(task);
		if (this.overtimeTaskCounter !== this.tasksWithSecondsLeft.length) {
			this.overtimeTaskCounter++;
		}
		if (this.overtimeTaskCounter === this.tasksWithSecondsLeft.length) {
			this.overtimeTaskCounter = 0;
		}
	} */

	async substractPercentually(): Promise<void> {
		let index = this.workingPeriod.activeTask + 1;
		let percentageIndex = 0;
		for (index; index < this.workingPeriod.tasks.length; ) {
			const substarctPart = this.futureTasksPercentage[percentageIndex] / 100;
			this.workingPeriod.tasks[index].secondsLeft = this.workingPeriod.tasks[index].secondsLeft - substarctPart;
			this.workingPeriod.tasks[index].secondsNeeded = this.workingPeriod.tasks[index].secondsNeeded - substarctPart;
			index++;
			percentageIndex++;
		}
	}

	//Assignment

	async createAssignment(): Promise<void> {
		console.log('createAssignment');
		if (this.assignmentContent.length > 0 || this.assignmentvHtmlContent.length > 0) {
			const assignment: TaskAssignment = {
				id: this.nextAssignmentId,
				type: this.assignmentDeadline.length > 0 || this.responsibleForAssignment.length > 0 ? 'task-todo' : 'task-note',
				content: this.assignmentContent,
				vHtml: this.assignmentvHtmlContent
			};
			if (this.assignmentDeadline.length > 0) assignment.deadline = new Date(this.assignmentDeadline).toISOString().substr(0, 10);
			if (this.responsibleForAssignment.length > 0) assignment.responsibleForAssignment = this.responsibleForAssignment;

			if (this.assignmentvHtmlContent.length > 0) {
				assignment.vHtmlObject = this.parseHTMLContent(this.assignmentvHtmlContent);
			}

			console.log(assignment.vHtmlObject);

			this.workingPeriod.tasks[this.activeTaskKey].assignments.push(assignment);
			this.nextAssignmentId++;
			this.cleanAssignmentForm();
			this.editedTaskId = this.workingPeriod.tasks[this.workingPeriod.activeTask].id;
			this.activeTaskKey = this.workingPeriod.activeTask;
			this.getPersonTasks();
			if (this.workingPeriod.status === 'finished') {
				MeetingStore.updateWorkingPeriod(this.workingPeriod);
			}
			this.scrollToNext(String(this.workingPeriod.tasks[this.workingPeriod.activeTask].id));
			this.workingPeriod.orderedListCount = await this.getOrderedListCount();
		}
	}

	async editAssignment(assignment: TaskAssignment, assignmentId: number, taskId: number): Promise<void> {
		this.editedAssignmentId = assignmentId;
		this.editedTaskId = taskId;
		this.activeTaskKey = this.workingPeriod.tasks.findIndex((task) => task.id === this.editedTaskId);
		this.assignmentType = assignment.type;
		this.assignmentContent = assignment.content;
		this.assignmentvHtmlContent = assignment.vHtml;

		if (assignment.responsibleForAssignment) this.responsibleForAssignment = assignment.responsibleForAssignment;
		if (assignment.deadline) this.assignmentDeadline = assignment.deadline;

		this.isHighlighted = true;
		setTimeout(() => {
			this.isHighlighted = false;
		}, 500);
		this.$root.$emit('bv::hide::tooltip');
	}

	async updateAssignment(): Promise<void> {
		if (this.assignmentContent.length > 0 || this.assignmentvHtmlContent.length > 0) {
			const assignment: TaskAssignment = {
				id: this.editedAssignmentId,
				type: this.assignmentDeadline.length > 0 || this.responsibleForAssignment.length > 0 ? 'task-todo' : 'task-note',
				content: this.assignmentContent,
				vHtml: this.assignmentvHtmlContent
			};
			if (this.assignmentDeadline.length > 0) assignment.deadline = new Date(this.assignmentDeadline).toISOString().substr(0, 10);
			if (this.responsibleForAssignment.length > 0) assignment.responsibleForAssignment = this.responsibleForAssignment;

			if (this.assignmentvHtmlContent.length > 0) {
				assignment.vHtmlObject = this.parseHTMLContent(this.assignmentvHtmlContent);
			}
			const target = this.workingPeriod.tasks[this.activeTaskKey].assignments.find((assignment) => assignment.id === this.editedAssignmentId);
			if (target) {
				const index = this.workingPeriod.tasks[this.activeTaskKey].assignments.indexOf(target, 0);
				this.workingPeriod.tasks[this.activeTaskKey].assignments[index] = assignment;
				this.editedTaskId = this.workingPeriod.tasks[this.workingPeriod.activeTask].id;
				this.activeTaskKey = this.workingPeriod.tasks.findIndex((task) => task.id === this.editedTaskId);
			}
			this.getPersonTasks();
			if (this.workingPeriod.status === 'finished') {
				MeetingStore.updateWorkingPeriod(this.workingPeriod);
			}
			this.assignmentEditMode = false;
			this.workingPeriod.orderedListCount = await this.getOrderedListCount();
		}
	}

	async addResponsibleForAssignment(responsible: MeetingParticipant): Promise<void> {
		this.responsibleForAssignment.push(responsible);
	}

	async removeResponsibleForAssignment(responsible: MeetingParticipant): Promise<void> {
		const index = this.responsibleForAssignment.indexOf(responsible, 0);
		this.responsibleForAssignment.splice(index, 1);
	}

	async cleanAssignmentForm(): Promise<void> {
		this.assignmentContent = '';
		this.assignmentvHtmlContent = '';
		this.responsibleForAssignment = [];
		this.assignmentDeadline = '';
		this.addToSelectedTask = false;
		this.editedAssignmentId = 0;
	}

	async deleteAssignment(): Promise<void> {
		if (this.assignmentEditMode && this.editedAssignmentId === this.assignmentToDelete.id) {
			this.assignmentType = '';
			this.assignmentContent = '';
			this.assignmentvHtmlContent = '';
			this.responsibleForAssignment = [];
			this.assignmentDeadline = '';
			this.assignmentEditMode = false;
		}
		if (this.workingPeriod.status !== 'finished') {
			const index = this.workingPeriod.tasks[this.assignmentTaskKeyToDelete].assignments.indexOf(this.assignmentToDelete, 0);
			this.workingPeriod.tasks[this.assignmentTaskKeyToDelete].assignments.splice(index, 1);
			this.assignmentToDelete = null as unknown as TaskAssignment;
			this.assignmentTaskKeyToDelete = 0;
			this.getPersonTasks();
			this.workingPeriod.orderedListCount = await this.getOrderedListCount();
		} else {
			//finished
			const index = this.workingPeriod.tasks[this.assignmentTaskKeyToDelete].assignments.indexOf(this.assignmentToDelete, 0);
			this.workingPeriod.tasks[this.assignmentTaskKeyToDelete].assignments.splice(index, 1);
			this.assignmentToDelete = null as unknown as TaskAssignment;
			this.assignmentTaskKeyToDelete = 0;
			this.getPersonTasks();
			this.workingPeriod.orderedListCount = await this.getOrderedListCount();
			MeetingStore.updateWorkingPeriod(this.workingPeriod);
		}
	}

	async selectActiveAssignment(taskId: number): Promise<void> {
		this.cleanAssignmentForm();
		this.assignmentEditMode = false;
		this.editedTaskId = taskId;
		this.activeTaskKey = this.workingPeriod.tasks.findIndex((task) => task.id === this.editedTaskId);
		this.addToSelectedTask = true;
	}

	getTaskAssignmentOptions(assignment: TaskAssignment): SelectItemOption[] {
		return getAssignmentOptions(assignment);
	}

	async getOrderedListCount(): Promise<number> {
		let orderedListCount = 0;
		this.workingPeriod.tasks.forEach((task) => {
			if (task.assignments.length > 0) {
				task.assignments.forEach((assignment) => {
					if (assignment.vHtmlObject) {
						assignment.vHtmlObject.forEach((vHtmlObject: any) => {
							if (vHtmlObject.tag && vHtmlObject.tag === 'ol') {
								orderedListCount++;
							}
						});
					}
				});
			}
		});
		return orderedListCount;
	}

	//Participants

	async addExtraParticipant(): Promise<void> {
		if (this.newParticipantName.length > 0 && !this.newMeetParticipantExists) {
			const participant: MeetingParticipant = {
				id: this.nextParticipantId,
				roles: ['External'],
				name: this.newParticipantName
			};
			this.workingPeriod.participants.push(participant);
			this.newParticipantName = '';
			this.nextParticipantId++;
		}
	}

	async getPersonTasks(): Promise<void> {
		const result: any[] = [];
		this.workingPeriod.participants.forEach((part) => {
			const emptyTasks: any[] = [];
			const persObj = {
				persId: part.id,
				persName: part.name,
				tasks: emptyTasks
			};

			this.workingPeriod.tasks.forEach((task) => {
				let numberOfAssignments = 0;
				const taskObj = {
					taskId: task.id,
					color: task.color,
					assignments: numberOfAssignments
				};
				task.assignments.forEach((assignment) => {
					if (assignment.responsibleForAssignment && assignment.responsibleForAssignment.length > 0) {
						assignment.responsibleForAssignment.forEach((responsible) => {
							if (part.id === responsible.id && part.name === responsible.name) {
								numberOfAssignments++;
							}
						});
						taskObj.assignments = numberOfAssignments;
					}
				});

				persObj.tasks.push(taskObj);
			});

			result.push(persObj);
		});
		this.personTasks = result;
		//return result;
	}

	//Getters
	get sortedWorkingPeriodParticipants(): MeetingParticipant[] {
		return this.workingPeriod.participants.sort((a: MeetingParticipant, b: MeetingParticipant) => {
			if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
				return -1;
			}
			if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
				return 1;
			}
			return 0;
		});
	}

	get futureTasksPercentage(): number[] {
		const futureTasks = [...this.tasksWithSecondsLeft];
		const futurePercentage = getPercentage(futureTasks);
		return futurePercentage;
	}

	get tasksWithSecondsLeft(): TaskResource[] {
		//const tasksWithSecondsLeft = this.workingPeriod.tasks.filter((task) => task.secondsLeft > 0);
		return this.workingPeriod.tasks.filter((task) => task.secondsLeft > 0);
	}

	get extraMeetingOptions(): SelectItemOption[] {
		const options: SelectItemOption[] = [];
		if (this.workingPeriod.status === 'finished') {
			options.push(
				{
					name: 'add-assignment',
					description: 'Add Note or To-Do',
					icon: {
						name: 'checkbox-marked-outline'
					}
				},
				{
					name: 'report',
					description: 'Download Report',
					icon: {
						name: 'tray-arrow-down'
					}
				}
			);
		}
		options.push({
			name: 'add-participant',
			description: 'Add Participant',
			icon: {
				name: 'account-plus'
			}
		});
		return options;
	}

	get highestTaskId(): number {
		return Math.max(...this.workingPeriod.tasks.map((task) => task.id));
	}

	get assignmentsCount(): number {
		const allAssignments: TaskAssignment[] = [];
		this.workingPeriod.tasks.forEach((task) => {
			task.assignments.forEach((assignment) => {
				allAssignments.push(assignment);
			});
		});
		return allAssignments.length;
	}

	get remainingSeconds(): number {
		const a = moment(this.workingPeriod.due);
		const diff = a.diff(moment(this.$attrs.now), 'seconds');
		return diff; // return difference between wpDue and Date now
	}

	get progress(): number {
		return 100 - (100 / this.workingPeriod.secondsNeeded) * this.workingPeriod.secondsLeft;
	}

	get taskProgress(): number {
		return 100 - (100 / this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsNeeded) * this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft;
	}

	get pauseProgress(): number {
		return 100 - (100 / this.workingPeriod.pause.secondsNeeded) * this.workingPeriod.pause.secondsLeft;
	}

	get bufferProgress(): number {
		return 100 - (100 / this.workingPeriod.buffer.secondsNeeded) * this.workingPeriod.buffer.secondsLeft;
	}

	get fullTime(): string {
		const seconds = this.workingPeriod.secondsNeeded;
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = seconds % 60;
		return (minutes < 10 ? '0' : '') + minutes + ':' + (secondsLeft < 10 ? '0' : '') + secondsLeft;
	}

	get remainingTimeDisplay(): string {
		const seconds = this.workingPeriod.secondsLeft;
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = seconds % 60;
		if (minutes == 0 && secondsLeft == 0) {
			return '00:00';
		}
		return (minutes < 10 ? '0' : '') + minutes + ':' + (secondsLeft < 10 ? '0' : '') + secondsLeft;
	}

	get remainingTaskTimeDisplay(): string {
		const seconds = this.workingPeriod.tasks[this.workingPeriod.activeTask].secondsLeft;
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = seconds % 60;
		if (minutes <= 0 && secondsLeft <= 0) {
			return '00:00';
		}
		return (minutes < 10 ? '0' : '') + minutes + ':' + (secondsLeft < 10 ? '0' : '') + secondsLeft;
	}

	get overtimeTimeDisplay(): string {
		const seconds = this.workingPeriod.overtimeSecondsCounted;
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = seconds % 60;
		if (minutes <= 0 && secondsLeft <= 0) {
			return '00:00';
		}
		return (minutes < 10 ? '0' : '') + minutes + ':' + (secondsLeft < 10 ? '0' : '') + secondsLeft;
	}

	get taskOvertimeTimeDisplay(): string {
		const seconds = this.workingPeriod.tasks[this.workingPeriod.activeTask].overtimeSecondsCounted;
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = seconds % 60;
		if (minutes <= 0 && secondsLeft <= 0) {
			return '00:00';
		}
		return (minutes < 10 ? '0' : '') + minutes + ':' + (secondsLeft < 10 ? '0' : '') + secondsLeft;
	}

	get remainingPauseTimeDisplay(): string {
		const seconds = this.workingPeriod.pause.secondsLeft;
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = seconds % 60;
		if (minutes == 0 && secondsLeft == 0) {
			return '00:00';
		}
		return (minutes < 10 ? '0' : '') + minutes + ':' + (secondsLeft < 10 ? '0' : '') + secondsLeft;
	}

	get remainingBufferTimeDisplay(): string {
		const seconds = this.workingPeriod.buffer.secondsLeft;
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = seconds % 60;
		if (minutes == 0 && secondsLeft == 0) {
			return '00:00';
		}
		return (minutes < 10 ? '0' : '') + minutes + ':' + (secondsLeft < 10 ? '0' : '') + secondsLeft;
	}

	get newMeetParticipantExists(): boolean {
		let target = null;
		this.workingPeriod.participants.forEach((participant) => {
			if (this.newParticipantName.length > 0 && participant.name === this.newParticipantName) {
				target = participant;
			}
		});
		if (target) return true;
		else return false;
	}

	get reversedTasks(): TaskResource[] {
		const reversedTasks: TaskResource[] = [];
		this.workingPeriod.tasks.forEach((task) => {
			reversedTasks.push(task);
		});
		return reversedTasks.reverse();
	}

	get validAssignmentDeadline(): boolean {
		if (this.assignmentDeadline) {
			const now: Date = new Date();
			let today = '';
			const dd = String(now.getDate()).padStart(2, '0');
			const mm = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!
			const yyyy = now.getFullYear();

			today = yyyy + '-' + mm + '-' + dd;
			return String(this.assignmentDeadline) >= today ? true : false;
		} else if (this.assignmentDeadline === '') {
			return true;
		} else {
			return false;
		}
	}

	get validateAssignmentContent(): boolean {
		if (this.assignmentContent && this.assignmentContent.length > 0) {
			if (this.assignmentContent.length < 40) {
				return true;
			}
			if (this.assignmentContent.length >= 40 && this.assignmentContent.indexOf(' ') >= 0) {
				return true;
			} else {
				return false;
			}
		} else if (this.assignmentContent.length === 0) {
			return true;
		} else return false;
	}

	get numberOfAllAssignments(): number {
		let amount = 0;
		this.workingPeriod.tasks.forEach((task) => (amount += task.assignments.length));
		return amount;
	}
}
